import { Authenticated, CrudFilters, HttpError, LayoutWrapper, useTranslate } from "@pankod/refine-core";

import {
    Space,
    PageHeader,
    BooleanField,
    Col,
    DateField,
    Row,
    ShowButton,
    Table,
    Button,
    DatePicker,
    Form,
    FormProps,
    Icons,
    Input,
    Select,
    // useTable,
    Statistic,
    Checkbox,
} from "@pankod/refine-antd";
import { useState } from "react";
import { IUser, IUserFilterVariables } from "interfaces/user";
import { useTable } from "hooks/useTable";

export const User_list: React.FC = () => {
    const { tableProps, searchFormProps } = useTable<
        IUser,
        HttpError,
        IUserFilterVariables
    >({
        resource: "user",
        initialSorter: [
            {
                field: "id",
                order: "desc",
            },
        ],
        resolveDataSource:  (dataSource:any) => {
            const { data: users } = dataSource;
            return users;
        },
        onSearch: (params: any) => {
            const filters: CrudFilters = [];
            const { q, createdAt, isKYC } = params;

            filters.push({
                field: "q",
                operator: "eq",
                value: q,
            });

            filters.push(
                {
                    field: "createdAt",
                    operator: "gte",
                    value: createdAt
                        ? createdAt[0].startOf("day").toISOString()
                        : undefined,
                },
                {
                    field: "createdAt",
                    operator: "lte",
                    value: createdAt
                        ? createdAt[1].endOf("day").toISOString()
                        : undefined,
                },
            );

            filters.push({
                field: "isKYC",
                operator: "eq",
                value: isKYC,
            });

            return filters;
        },
        syncWithLocation: false,
    });

    const t = useTranslate();
    const [filterType, setFilterType] = useState(true);

    return (
        <Authenticated>
            <LayoutWrapper>
                <Row gutter={[16, 16]}>
                    <Col lg={24} xs={24}>
                        <PageHeader title="用户列表" ghost={false} extra={
                            <Space key="extra-buttons" wrap>
                                <Button type="default" icon={<Icons.SwapOutlined />} onClick={() => { setFilterType(!filterType) }} >
                                    {filterType ? '查找用户' : '条件筛选'}
                                </Button>
                            </Space>
                        }>
                            {filterType || <  SeachUser formprops={searchFormProps} />}
                            {filterType && <  WhiteFilter formprops={searchFormProps} />}
                        </PageHeader>
                    </Col>
                    <Col xl={24} xs={24}>
                        <Table {...tableProps} rowKey="id">
                            <Table.Column
                                key="name"
                                dataIndex="name"
                                title={t("users.fields.name")}
                            />
                            <Table.Column
                                key="address"
                                dataIndex="address"
                                title={t("users.fields.address")}
                            />
                            <Table.Column
                                key="mkv"
                                dataIndex="mkv"
                                title={t("users.fields.mkv")}
                                render={(value) =>
                                    <Statistic title="mkv" value={value} />
                                }
                            />
                            <Table.Column
                                key="lp"
                                dataIndex="lp"
                                title={t("users.fields.lp")}
                            />
                            <Table.Column
                                key="nft"
                                dataIndex="nft"
                                title={t("users.fields.nft")}
                            />
                            <Table.Column
                                key="buy"
                                dataIndex="buy"
                                title={t("users.fields.buy")}
                            />
                            <Table.Column
                                key="lottery"
                                dataIndex="lottery"
                                title={t("users.fields.lottery")}
                            />
                            <Table.Column
                                key="spread"
                                dataIndex="spread"
                                title={t("users.fields.spread")}
                            />
                            <Table.Column
                                key="isKYC"
                                dataIndex="isKYC"
                                title={t("users.fields.isKYC.label")}
                                render={(value) => <BooleanField value={value} />}
                            />
                            <Table.Column
                                key="isConTwitter"
                                dataIndex="isConTwitter"
                                title={t("users.fields.isConTwitter")}
                                render={(value) => <BooleanField value={value} />}
                            />
                            <Table.Column
                                key="isConDiscord"
                                dataIndex="isConDiscord"
                                title={t("users.fields.isConDiscord")}
                                render={(value) => <BooleanField value={value} />}
                            />
                            <Table.Column
                                key="createdAt"
                                dataIndex="createdAt"
                                title={t("users.fields.createdAt")}
                                render={(value) => (
                                    <DateField value={value} format="LLL" />
                                )}
                                sorter
                            />
                            <Table.Column<IUser>
                                title={t("table.actions")}
                                render={(_, record) => (
                                    <ShowButton hideText recordItemId={record.id} />
                                )}
                            />
                        </Table>
                    </Col>
                </Row>
            </LayoutWrapper>
        </Authenticated>
    );
};


const SeachUser: React.FC<{ formprops: FormProps }> = (props) => {
    const t = useTranslate();

    const { RangePicker } = DatePicker;
    return (
        <Form layout="vertical" {...props.formprops}>
            <Row gutter={[10, 0]} align="bottom">
                <Col xs={24} md={12} xl={8} >
                    <Form.Item label={t("users.filter.search.label")} name="q">
                        <Input
                            placeholder={t("users.filter.search.placeholder")}
                            prefix={<Icons.SearchOutlined />}
                        />
                    </Form.Item>
                </Col>
                <Col xs={12} md={9} xl={6} >
                    <Form.Item
                        label={t("users.filter.createdAt.label")}
                        name="createdAt"
                    >
                        <RangePicker style={{ width: "100%" }} />
                    </Form.Item>
                </Col>
                <Col xs={12} md={8} xl={5} >
                    <Form.Item
                        label={t("users.filter.isConnect")}
                        name="isKYC"
                    >
                        <Checkbox>{t("users.fields.isConTwitter")}</Checkbox>
                        <Checkbox>{t("users.fields.isConDiscord")}</Checkbox>
                    </Form.Item>
                </Col>
                <Col xs={6} md={4} xl={2} >
                    <Form.Item>
                        <Button
                            style={{ width: "100%" }}
                            htmlType="submit"
                            type="primary"
                        >
                            {t("users.filter.submit")}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

const WhiteFilter: React.FC<{ formprops: FormProps }> = (props) => {
    const t = useTranslate();
    return (
        <Form layout="vertical" {...props.formprops}>
            <Row gutter={[10, 0]} align="bottom">
                <Col xs={12} md={8} xl={6} >
                    <Form.Item
                        label={t("users.filter.mkv")}
                        name="mkv"
                    >
                        <Input.Group compact>
                            <Input style={{ width: "40%", textAlign: 'center' }} placeholder="Minimum" />
                            <Input
                                className="site-input-split"
                                style={{ width: 30, pointerEvents: 'none', }}
                                placeholder="~" disabled
                            />
                            <Input
                                className="site-input-right"
                                style={{
                                    width: "40%",
                                    textAlign: 'center',
                                }}
                                placeholder="Maximum"
                            />
                        </Input.Group>
                    </Form.Item>
                </Col>
                <Col xs={12} md={8} xl={6} >
                    <Form.Item
                        label={t("users.filter.lp")}
                        name="lp"
                    >
                        <Input.Group compact>
                            <Input style={{ width: "40%", textAlign: 'center' }} placeholder="Minimum" />
                            <Input
                                className="site-input-split"
                                style={{ width: 30, pointerEvents: 'none', }}
                                placeholder="~" disabled
                            />
                            <Input
                                className="site-input-right"
                                style={{
                                    width: "40%",
                                    textAlign: 'center',
                                }}
                                placeholder="Maximum"
                            />
                        </Input.Group>
                    </Form.Item>
                </Col>
                <Col xs={12} md={8} xl={6} >
                    <Form.Item
                        label={t("users.filter.nft")}
                        name="nft"
                    >
                        <Input.Group compact>
                            <Input style={{ width: "40%", textAlign: 'center' }} placeholder="Minimum" />
                            <Input
                                className="site-input-split"
                                style={{ width: 30, pointerEvents: 'none', }}
                                placeholder="~" disabled
                            />
                            <Input
                                className="site-input-right"
                                style={{
                                    width: "40%",
                                    textAlign: 'center',
                                }}
                                placeholder="Maximum"
                            />
                        </Input.Group>
                    </Form.Item>
                </Col>
                <Col xs={12} md={8} xl={6} >
                    <Form.Item
                        label={t("users.filter.buy")}
                        name="buy"
                    >
                        <Input.Group compact>
                            <Input style={{ width: "40%", textAlign: 'center' }} placeholder="Minimum" />
                            <Input
                                className="site-input-split"
                                style={{ width: 30, pointerEvents: 'none', }}
                                placeholder="~" disabled
                            />
                            <Input
                                className="site-input-right"
                                style={{
                                    width: "40%",
                                    textAlign: 'center',
                                }}
                                placeholder="Maximum"
                            />
                        </Input.Group>
                    </Form.Item>
                </Col>
                <Col xs={12} md={8} xl={6} >
                    <Form.Item
                        label={t("users.filter.lottery")}
                        name="lottery"
                    >
                        <Input.Group compact>
                            <Input style={{ width: "40%", textAlign: 'center' }} placeholder="Minimum" />
                            <Input
                                className="site-input-split"
                                style={{ width: 30, pointerEvents: 'none', }}
                                placeholder="~" disabled
                            />
                            <Input
                                className="site-input-right"
                                style={{
                                    width: "40%",
                                    textAlign: 'center',
                                }}
                                placeholder="Maximum"
                            />
                        </Input.Group>
                    </Form.Item>
                </Col>
                <Col xs={12} md={8} xl={6} >
                    <Form.Item
                        label={t("users.filter.spread")}
                        name="spread"
                    >
                        <Input.Group compact>
                            <Input style={{ width: "40%", textAlign: 'center' }} placeholder="Minimum" />
                            <Input
                                className="site-input-split"
                                style={{ width: 30, pointerEvents: 'none', }}
                                placeholder="~" disabled
                            />
                            <Input
                                className="site-input-right"
                                style={{
                                    width: "40%",
                                    textAlign: 'center',
                                }}
                                placeholder="Maximum"
                            />
                        </Input.Group>
                    </Form.Item>
                </Col>
                <Col xs={12} md={8} xl={6} >
                    <Form.Item style={{ width: "60%" }}
                        label={t("users.filter.isKYC.label")}
                        name="isKYC"
                    >
                        <Select
                            allowClear
                            placeholder={t("users.filter.isKYC.placeholder")}
                            options={[
                                {
                                    label: t("users.filter.isKYC.true"),
                                    value: "true",
                                },
                                {
                                    label: t("users.filter.isKYC.false"),
                                    value: "false",
                                },
                            ]}
                        />
                    </Form.Item>
                </Col>
                <Col xs={6} md={3} xl={2} >
                    <Form.Item>
                        <Button
                            style={{ width: "100%" }}
                            htmlType="submit"
                            type="primary"
                        >
                            {t("users.filter.submit")}
                        </Button>
                    </Form.Item>
                </Col>
                <Col xs={6} md={3} xl={2} >
                    <Form.Item>
                        <Button style={{ width: "100%" }} >
                            {t("users.filter.add")}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

