import React from "react";
import { UnorderedListOutlined } from "@ant-design/icons";

import {
    useTranslate,
    useResource,
    useRouterContext,
    userFriendlyResourceName,
    ITreeMenu,
} from "@pankod/refine-core";
import qs from "qs";
import { IMenuItem } from "interfaces";

type useMenuReturnType = {
    ns: any;
    selectedKey: string;
    menuItems: ITreeMenu[];
    initRootSubmenu: (rootKeys: string[]) => string[];
};

/**
 * `useMenu` is used to get menu items of the default sidebar.
 * These items include a link to dashboard page (if it exists) and links to the user defined resources
 * (passed as children to {@link https://refine.dev/docs/core/components/refine-config `<Refine>`}).
 * This hook can also be used to build custom menus, which is also used by default sidebar to show menu items.
 *
 * @see {@link https://refine.dev/docs/ui-frameworks/antd/hooks/resource/useMenu} for more details.
 */
export const useMenu: () => useMenuReturnType = () => {
    const { resources } = useResource();
    const translate = useTranslate();

    const { useLocation, useParams } = useRouterContext();
    const location = useLocation();
    const params = useParams<{ resource: string }>();
    const { ns } = qs.parse(location.search?.substring(1));

    let selectedResource = resources.find(
        (el) => location?.pathname === `/${el.route}`,
    );

    // for no ssr
    if (!selectedResource) {
        selectedResource = resources.find(
            (el) => params?.resource === (el.route as string),
        );
    }

    let selectedKey: string;
    if (selectedResource?.route) {
        selectedKey = `/${selectedResource?.route}`;
    } else if (location.pathname === "/") {
        selectedKey = "/";
    } else {
        selectedKey = location?.pathname;
    }

    const menuItems: IMenuItem[] = React.useMemo(
        () => [
            ...resources.map((resource) => {
                const route = `/${resource.route}`;

                return {
                    ...resource,
                    icon: resource.icon ?? <UnorderedListOutlined />,
                    route: route,
                    key: route,
                    label:
                        resource.label ??
                        translate(
                            `${resource.name}.${resource.name}`,
                            userFriendlyResourceName(resource.name, "plural"),
                        ),
                };
            }),
        ],
        [resources,translate],
    );

    const initRootSubmenu = (rootKeys: string[]) => {
        return rootKeys.filter((e)=>{
            return (selectedKey).indexOf(e) === 1
        });
    };

    return {
        ns: ns?.toString(),
        selectedKey,
        menuItems,
        initRootSubmenu
    };
};
