import { BaseRecord, useOne, useTranslate, useUpdate } from "@pankod/refine-core";
import { Authenticated, LayoutWrapper } from "@pankod/refine-core";

import {
    Typography,
    Button,
    Space,
    PageHeader,
    Icons,
    Spin,
    Card,
    Table,
    Drawer,
    Form,
    Input,
    Modal,
    InputNumber,
    message,
    FormInstance,
    Popconfirm,
} from "@pankod/refine-antd";
import { useEffect, useRef, useState } from "react";

const { Paragraph } = Typography;
const { EditOutlined, RedoOutlined, SaveOutlined, DeleteOutlined } = Icons;

// reset form fields when modal is form, closed
const useResetFormOnCloseModal = ({ form, visible }: { form: FormInstance; visible: any }) => {
    const prevVisibleRef = useRef<boolean>();
    useEffect(() => {
        prevVisibleRef.current = visible;
    }, [visible]);
    const prevVisible = prevVisibleRef.current;

    useEffect(() => {
        if (!visible && prevVisible) {
            form.resetFields();
        }
    });
};

const rowKey = "time"
const resource = "config"
const conf_key = "base/daily_lottery"
export const Daily_lottery: React.FC = () => {

    const translate = useTranslate();
    const { data: resp, isLoading, refetch, isFetching } = useOne({ id: conf_key, resource });
    const resetRecord = (recMap: { [x: string]: any; }) => {
        const recArray = Object.keys(recMap).map(
            (rowKeyIndex) => recMap[rowKeyIndex]
        );
        setRecord([...recArray]);
        setDrawerValue(JSON.stringify(recMap));
    };
    const buttonDisabled = isLoading || isFetching;
    const [record, setRecord] = useState<object[]>([]);
    useEffect(() => {
        if (resp?.data?.data && !isFetching) {
            const recMap = JSON.parse(resp.data.data);
            resetRecord(recMap);
            console.log(`[${conf_key}]`, recMap, { isFetching });
        }
    }, [resp, isFetching]);
    const editableAction = (_: any, row: any, index: any) => {
        return <Space>
            <Popconfirm
                key="delete" okType="danger"
                okText={translate("buttons.delete", "Delete")}
                cancelText={translate("buttons.cancel", "Cancel")}
                title={translate("buttons.confirm", "Are you sure?")}
                okButtonProps={{ disabled: isLoading }}
                onConfirm={(): void => onEditFormDelete(row[rowKey])}
            >
                <Button danger loading={isLoading} icon={<DeleteOutlined />}></Button>
            </Popconfirm>
        </Space>
    }
    const onEditFormDelete = (index: any) => {
        if (index !== undefined) {
            const recMap = JSON.parse(resp?.data.data);
            delete recMap[index];
            mutate({ resource, id: conf_key, values: recMap });
        }
    }

    // Drawer
    const [drawerValue, setDrawerValue] = useState("");
    const [Drawervisible, setDrawerVisible] = useState(false);
    const mutationResult = useUpdate<BaseRecord>();
    const { mutate, isLoading: mutateIsLoading } = mutationResult;
    const handleSaveJson = () => {
        // valid
        try {
            JSON.parse(drawerValue);
        } catch (error) {
            return message.error('不是有效的json');
        }

        // submit
        mutate({ resource, id: conf_key, values: JSON.parse(drawerValue) }, {
            onSuccess: () => {
                setDrawerVisible(false);
            },
        });
    };

    // modal
    const [form] = Form.useForm();
    const [modalVisible, setModalVisible] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    useResetFormOnCloseModal({ form, visible: modalVisible });
    const handleOk = (value: any) => {
        form.submit();
    };
    const onModalFormFinish = (values: any) => {
        const recMap = JSON.parse(resp?.data.data);
        recMap[values[rowKey]] = values;
        mutate({ resource, id: conf_key, values: recMap }, {
            onSuccess: () => {
                setModalLoading(false);
                setModalVisible(false);
            },
            onError: () => {
                setModalLoading(false);
            }
        });
    };
    const handleCancel = () => {
        setModalVisible(false);
    };

    return (
        <Authenticated>
            <LayoutWrapper>
                <PageHeader title="相关配置 > 每日抽奖设置" ghost={false} extra={
                    <Space key="extra-buttons" wrap>
                        <Button icon={<RedoOutlined spin={isFetching} />}
                            onClick={() => refetch()}
                        > {translate("buttons.refresh", "Refresh")} </Button>
                        <Button disabled={buttonDisabled} icon={<EditOutlined />}
                            onClick={() => setDrawerVisible(true)}
                        > JSON </Button>
                    </Space>
                }>
                    <Spin spinning={buttonDisabled}>
                        <Card bordered={false} >
                            <Paragraph>每个用户每日可以参与10次抽奖，第一次免费，后面每一次需要收取5 MKV。</Paragraph>
                            <Paragraph>每次抽奖扣除10点经验值，不奖励额外经验。用户完成抽奖行为，奖励数据统计到用户系统。</Paragraph>
                            <Paragraph>抽奖需求：奖励2和奖励3；奖励5和奖励6；奖励8和奖励9；奖励1和奖励7可以互换，奖励4、10固定不变。</Paragraph>
                            <Table rowKey={rowKey} dataSource={record} >
                                <Table.Column title="抽奖次数" dataIndex="time" />
                                <Table.Column title="每次消耗mkv" dataIndex="mkv" />
                                <Table.Column title="奖励经验" dataIndex="exp" />
                                <Table.Column title="随机材料" dataIndex="mat" />
                                <Table.Column width='20%' title={translate("table.actions")} render={editableAction} />
                            </Table>
                            <div style={{ textAlign: "center", marginTop: 24 }} >
                                <Button type="primary" disabled={buttonDisabled} onClick={() => { setModalVisible(true) }}>
                                    添加配置
                                </Button>
                            </div>
                        </Card>
                    </Spin>
                </PageHeader>

                <Modal
                    title="添加配置" visible={modalVisible}
                    onOk={handleOk} onCancel={handleCancel}
                    footer={[
                        <Button key="back" onClick={handleCancel}>取消</Button>,
                        <Button key="submit" type="primary" loading={modalLoading} onClick={handleOk}>保存</Button>
                    ]}
                >
                    <Form form={form} labelCol={{ span: 6 }} onFinish={onModalFormFinish}>
                        <Form.Item rules={[{ required: true }]} label="抽奖次数" name="time"><InputNumber style={{ width: '100%' }} /></Form.Item>
                        <Form.Item rules={[{ required: true }]} label="每次消耗mkv" name="mkv"><InputNumber style={{ width: '100%' }} /></Form.Item>
                        <Form.Item rules={[{ required: true }]} label="奖励经验" name="exp"><InputNumber style={{ width: '100%' }} /></Form.Item>
                        <Form.Item rules={[{ required: true }]} label="随机材料" name="mat"><InputNumber style={{ width: '100%' }} /></Form.Item>
                    </Form>
                </Modal>

                <Drawer title="编辑JSON" placement="right" onClose={() => { setDrawerVisible(false) }} visible={Drawervisible}>
                    <Input.TextArea rows={10} value={drawerValue} onChange={e => setDrawerValue(e.target.value)} />
                    <Space
                        key="action-buttons"
                        style={{ float: "right", marginTop: 24, marginRight: 24 }}
                    >
                        <Button type="primary" icon={<SaveOutlined spin={mutateIsLoading} />} htmlType="submit" onClick={handleSaveJson}>
                            {translate("buttons.save", "Save")}
                        </Button>
                    </Space>
                </Drawer>

            </LayoutWrapper>
        </Authenticated>
    );
};
