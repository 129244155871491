import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const modules = {
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image', 'video', 'code'],
        ['clean']
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        // matchVisual: false,
    }
};
const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video', 'code'
];

interface OnChangeHandler {
    (e: any): void;
}

type Props = {
    value: string;
    placeholder: string;
    onChange: OnChangeHandler;
};
interface RichTextProps {
    value?: string;
    placeholder: string;
    onChange?: (value: any) => void;
}

const RichTextEditor: React.FC<RichTextProps> = ({ value, onChange, placeholder }) => {
    return (
        <>
            <ReactQuill
                theme="snow"
                value={value || ''}
                modules={modules}
                formats={formats}
                onChange={onChange}
                placeholder={placeholder}
            />
        </>
    );
};

export default RichTextEditor;