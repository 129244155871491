import { useOne, useTranslate } from "@pankod/refine-core";
import { Authenticated, LayoutWrapper } from "@pankod/refine-core";

import {
    Button,
    Space,
    PageHeader,
    Icons,
    Spin,
    Card,
    Table,
} from "@pankod/refine-antd";

export const White_list: React.FC = () => {

    const translate = useTranslate();
    const { data, isLoading, remove, refetch, isFetching } = useOne({
        resource: "user", id: "white_list",
    });
    const record = data?.data;
    const buttonDisabled = isLoading || isFetching;

    const handleRefresh = () => {
        remove();
        refetch();
    };

    return (
        <Authenticated>
            <LayoutWrapper>
                <PageHeader title="白名单列表" ghost={false} extra={
                    <Space key="extra-buttons" wrap>
                        <Button icon={<Icons.RedoOutlined spin={isFetching} />}
                            onClick={() => handleRefresh()}
                        > {translate("buttons.refresh", "Refresh")} </Button>
                    </Space>
                }>
                    <Spin spinning={buttonDisabled}>
                        <Card bordered={false} >
                            <Table rowKey="min" dataSource={record?.data} >
                                <Table.Column title="白名单名称" dataIndex="name" />
                                <Table.Column title="白名单人数" dataIndex="amount" />
                                <Table.Column title="备注" dataIndex="remark" />
                                <Table.Column title="添加时间" dataIndex="createdAt" />
                                <Table.Column title="操作"  render={(_, record) => (
                                    <Button icon={<Icons.DeleteOutlined />}> 删除</Button>
                                )}/>
                            </Table>
                        </Card>
                    </Spin>
                </PageHeader>

            </LayoutWrapper>
        </Authenticated>
    );
};
