
import axios from 'axios';
import { AuthProvider } from "@pankod/refine-core";

export const TOKEN_KEY = "maker-auth";
export const API_URL = process.env.REACT_APP_API_URl ?? "";
// export const API_URL = "http://localhost:3000/admin";
export const axiosInstance = axios.create();

export const authProvider: AuthProvider = {
  login: async ({ username, password, code }) => {
    try {
      let url = API_URL + (code ? "/login_github" : "/signIn");
      const { data: response, status } = await axiosInstance.post(url, { username, password, code });
      if (status === 200) {
        const { data, code, message } = response;
        if (code === 0) {
          console.log("[login]",data);
          localStorage.setItem(TOKEN_KEY, data.token);
          localStorage.setItem("user", JSON.stringify(data.user));
          localStorage.setItem("expiresAt", data.expires_at.toString());
          axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
          return Promise.resolve();
        } else {
          if (message) return Promise.reject(new Error(message));
        }
      } 
    } catch (error) {
      if(axios.isAxiosError(error) && error.response ){
        if (error.response.data.message) {
          return Promise.reject(new Error(error.response.data.message));
        }
      }
      return Promise.reject(error);
    }
  },
  logout: () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem("user");
    localStorage.removeItem("expiresAt");
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const expiresAt = localStorage.getItem("expiresAt");
      if (expiresAt) {
        return new Date().getTime() / 1000 < +expiresAt
          ? Promise.resolve()
          : Promise.reject();
      }
    }

    return Promise.reject();
  },
  getPermissions: () => Promise.resolve(),
  getUserIdentity: async () => {
    const user = localStorage.getItem("user");
    if (user) {
      return Promise.resolve(JSON.parse(user));
    }
  },
};
