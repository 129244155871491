import React, { useEffect, useState } from "react";
import "@pankod/refine-core";
import qs from "qs";
import { useRouterContext } from "@pankod/refine-core";
import { API_URL } from "../../authProvider";

import {
    Row,
    Col,
    AntdLayout,
    Card,
    Typography,
    Form,
    Input,
    Button,
    Checkbox,
    Icons,
} from "@pankod/refine-antd";

import {
    layoutStyles,
    containerStyles,
    titleStyles,
    imageContainer,
} from "./styles";

import { useLogin, useTranslate } from "@pankod/refine-core";

const { GithubOutlined } = Icons;
const { Title } = Typography;
export interface ILoginForm {
    username?: string;
    password?: string;
    remember?: boolean;
    code?: string;
}

/**
 * **refine** has a default login page form which is served on `/login` route when the `authProvider` configuration is provided.
 *
 * @see {@link https://refine.dev/docs/api-references/components/refine-config#loginpage} for more details.
 */
export const Login: React.FC = () => {
    const [form] = Form.useForm<ILoginForm>();
    const translate = useTranslate();

    const { mutate: login, isLoading,isError } = useLogin<ILoginForm>();

    const [githubLogin, setGithubLoging] = useState<boolean>(false);
    const [githubError, setGithubError] = useState<boolean>(false);
    
    const { useLocation } = useRouterContext();
    const { search } = useLocation();
    const { code } = qs.parse(search?.substring(1));
    useEffect(() => {
        if( code && !githubError){
            if(!githubLogin){
                setGithubLoging(true);
                login({ code: code.toString() });
            } else if(isError){
                setGithubLoging(false);
                setGithubError(true);
            }
        }
    }, [code, githubLogin,isError, githubError, login]);
    const login_github = ()=>{
        let param = `redirect=github&callback=${window.location.href}`
        window.location.href=`${API_URL}/login_github?${param}`;
    };

    const CardTitle = (
        <Title level={3} style={titleStyles}>
            {translate("pages.login.title", "Sign in your account")}
        </Title>
    );

    return (
        <AntdLayout style={layoutStyles}>
            <Row
                justify="center"
                align="middle"
                style={{
                    height: "100vh",
                }}
            >
                <Col xs={22}>
                    <div style={containerStyles}>
                        <div style={imageContainer}>
                            <img src={"/maker.png"} alt="Maker Logo" />
                        </div>
                        <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
                            <Form<ILoginForm>
                                layout="vertical"
                                form={form}
                                onFinish={(values) => {
                                    login(values);
                                }}
                                requiredMark={false}
                                initialValues={{
                                    remember: false,
                                }}
                            >
                                <Form.Item
                                    name="username"
                                    label={translate(
                                        "pages.login.username",
                                        "Username",
                                    )}
                                    rules={[{ required: true }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder={translate(
                                            "pages.login.username",
                                            "Username",
                                        )}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    label={translate(
                                        "pages.login.password",
                                        "Password",
                                    )}
                                    rules={[{ required: true }]}
                                    style={{ marginBottom: "12px" }}
                                >
                                    <Input
                                        type="password"
                                        placeholder="●●●●●●●●"
                                        size="large"
                                    />
                                </Form.Item>
                                <div style={{ marginBottom: "12px" }}>
                                    <Form.Item
                                        name="remember"
                                        valuePropName="checked"
                                        noStyle
                                    >
                                        <Checkbox
                                            style={{
                                                fontSize: "12px",
                                            }}
                                        >
                                            {translate(
                                                "pages.login.remember",
                                                "Remember me",
                                            )}
                                        </Checkbox>
                                    </Form.Item>

                                    <a
                                        style={{
                                            float: "right",
                                            fontSize: "12px",
                                        }}
                                        href="#"
                                    >
                                        {translate(
                                            "pages.login.forgotPassword",
                                            "Forgot password?",
                                        )}
                                    </a>
                                </div>
                                <Button
                                    type="primary"
                                    size="large"
                                    htmlType="submit"
                                    disabled={githubLogin}
                                    loading={!githubLogin && isLoading}
                                    block
                                >
                                    {translate("pages.login.signin", "Sign in")}
                                </Button>
                                
                                <div style={{ marginTop: 16 }}>
                                    <Button
                                        size="large"
                                        block
                                        icon={<GithubOutlined />}
                                        loading={githubLogin}
                                        onClick={login_github}
                                    >
                                        {translate("pages.login.github_signin", "Github Sign in")}
                                    </Button>
                                </div>
                            </Form>
                            {/* <div style={{ marginTop: 8 }}>
                                <Text style={{ fontSize: 12 }}>
                                    {translate(
                                        "pages.login.noAccount",
                                        "Don’t have an account?",
                                    )}{" "}
                                    <a href="#" style={{ fontWeight: "bold" }}>
                                        {translate(
                                            "pages.login.signup",
                                            "Sign up",
                                        )}
                                    </a>
                                </Text>
                            </div> */}
                        </Card>
                    </div>
                </Col>
            </Row>
        </AntdLayout>
    );
};
