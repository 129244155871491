import { Authenticated, LayoutWrapper } from "@pankod/refine-core";

import {
    Typography,
    Space,
    PageHeader,
    Card,
} from "@pankod/refine-antd";

const { Title, Text } = Typography;
export const Week_shop: React.FC = () => {

    return (
        <Authenticated>
            <LayoutWrapper>
                <PageHeader title="相关配置 > 每周商店" ghost={false} extra={
                    <Space key="extra-buttons" wrap>
                    </Space>
                }>
                    <Card bordered={false} >
                        <Title level={5}>每周六、周日，商店开放购买权限。每个用户每项每天只能购买一次，商店品种分6类，如下图所示：</Title>
                        <Text>商店材料配置</Text>
                    </Card>
                </PageHeader>
            </LayoutWrapper>
        </Authenticated>
    );
};
