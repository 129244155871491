import { BaseRecord, useCreate, useDelete, useOne, useRouterContext, useTranslate, useUpdate } from "@pankod/refine-core";
import { Authenticated, LayoutWrapper } from "@pankod/refine-core";
import { Prism } from 'react-syntax-highlighter';
import JSON5 from "json5";

import {
    Typography,
    Button,
    Space,
    PageHeader,
    Icons,
    Spin,
    Card,
    Table,
    Drawer,
    Form,
    Input,
    Modal,
    message,
    FormInstance,
    Popconfirm,
    DateField,
} from "@pankod/refine-antd";
import { useEffect, useRef, useState } from "react";
import qs from "qs";
import { useConf } from "hooks/useConf";

const { EditOutlined, RedoOutlined, SaveOutlined, DeleteOutlined } = Icons;

// reset form fields when modal is form, closed
const useResetFormOnCloseModal = ({ form, visible }: { form: FormInstance; visible: any }) => {
    const prevVisibleRef = useRef<boolean>();
    useEffect(() => {
        prevVisibleRef.current = visible;
    }, [visible]);
    const prevVisible = prevVisibleRef.current;

    useEffect(() => {
        if (!visible && prevVisible) {
            form.resetFields();
        }
    });
};

const rowKey = "key"
const resource = "config"
export const Common: React.FC = () => {

    const { nsMaps } = useConf();
    const translate = useTranslate();
    const { useLocation } = useRouterContext();
    const { search } = useLocation();
    const { ns } = qs.parse(search?.substring(1));
    const namespace = ns?.toString() ?? "";

    const { data: resp, isLoading, refetch, isFetching } = useOne({ id: namespace, resource });
    const buttonDisabled = isLoading || isFetching;
    const [record, setRecord] = useState<object[]>([]);
    useEffect(() => {
        if (namespace && resp?.data?.data && !isFetching) {
            setRecord([...resp.data.data]);
            // console.log(`[${namespace}] `, resp.data.data, { isFetching });
        }
    }, [resp, isFetching, namespace]);


    const editableAction = (_: any, row: any, index: any) => {
        return <Space>
            <Button icon={<EditOutlined />} onClick={() => {
                drawerForm.setFieldsValue({ ...row });
                setDrawerVisible(true);
            }}></Button>
            <Popconfirm
                key="delete" okType="danger"
                okText={translate("buttons.delete", "Delete")}
                cancelText={translate("buttons.cancel", "Cancel")}
                title={translate("buttons.confirm", "Are you sure?")}
                okButtonProps={{ disabled: isLoading }}
                onConfirm={(): void => onEditFormDelete(row[rowKey])}
            >
                <Button danger loading={isLoadingDel} icon={<DeleteOutlined />}></Button>
            </Popconfirm>
        </Space>
    }
    const { mutate: mutateDel, isLoading: isLoadingDel } = useDelete<BaseRecord>();
    const onEditFormDelete = (key: any) => {
        if (key !== undefined) {
            mutateDel({ resource, id: `${namespace}/${key}` }, {
                onSuccess: () => refetch()
            });
        }
    }

    // Modify Drawer
    const [drawerForm] = Form.useForm();
    const [Drawervisible, setDrawerVisible] = useState(false);
    const mutationResult = useUpdate<BaseRecord>();
    const { mutate, isLoading: mutateIsLoading } = mutationResult;
    const onEditFormFinish = (recMap: any) => {
        try {
            JSON5.parse(recMap.value);
        } catch (error) {
            return message.error('不是有效的json');
        }
        mutate({ resource, id: 'modify', values: recMap }, {
            onSuccess: () => {
                setDrawerVisible(false);
                refetch();
            }
        });
    };

    // Add Modal
    const [form] = Form.useForm();
    const [modalVisible, setModalVisible] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const { mutate: addMutate } = useCreate<BaseRecord>();
    useResetFormOnCloseModal({ form, visible: modalVisible });
    const handleOk = (value: any) => {
        form.submit();
    };
    const onModalFormFinish = (recMap: any) => {
        try {
            JSON5.parse(recMap.value);
        } catch (error) {
            console.error(error);
            return message.error('不是有效的json');
        }
        addMutate({ resource, values: recMap }, {
            onSuccess: () => {
                setModalLoading(false);
                setModalVisible(false);
                refetch();
            },
            onError: () => {
                setModalLoading(false);
            }
        });
    };
    const handleCancel = () => {
        setModalVisible(false);
    };

    return (
        <Authenticated>
            <LayoutWrapper>
                <PageHeader title={`通用配置 > ${nsMaps[namespace]?.label}`} ghost={false} extra={
                    <Space key="extra-buttons" wrap>
                        <Button icon={<RedoOutlined spin={isFetching} />}
                            onClick={() => refetch()}
                        > {translate("buttons.refresh", "Refresh")} </Button>
                    </Space>
                }>
                    <Spin spinning={buttonDisabled}>
                        <Card bordered={false} >
                            <Typography.Title level={5}>{nsMaps[namespace]?.desc}</Typography.Title>
                            <Table rowKey={rowKey} dataSource={record} expandable={{
                                rowExpandable: () => true,
                                expandedRowRender: (record:any) => <Prism language="json5">{record.value} </Prism >,
                            }}>
                                <Table.Column title="key" dataIndex="key" />
                                <Table.Column title="name" dataIndex="name" />
                                <Table.Column title="description" dataIndex="description" />
                                <Table.Column title="updatedAt" dataIndex="updatedAt" render={(value) => <DateField value={value} format="LLL" />} />
                                <Table.Column width='20%' title={translate("table.actions")} render={editableAction} />
                            </Table>
                            <div style={{ textAlign: "center", marginTop: 24 }} >
                                <Button type="primary" disabled={buttonDisabled} onClick={() => { setModalVisible(true) }}>
                                    添加配置
                                </Button>
                            </div>
                        </Card>
                    </Spin>
                </PageHeader>

                <Modal
                    title="添加配置" visible={modalVisible}
                    onOk={handleOk} onCancel={handleCancel}
                    footer={[
                        <Button key="back" onClick={handleCancel}>取消</Button>,
                        <Button key="submit" type="primary" loading={modalLoading} onClick={handleOk}>保存</Button>
                    ]}
                >
                    <Form form={form} labelCol={{ span: 6 }} onFinish={onModalFormFinish}>
                        <Form.Item hidden={true} label="namespace" name="namespace" initialValue={namespace}><Input /></Form.Item>
                        <Form.Item rules={[{ required: true }]} label="key" name="key"><Input /></Form.Item>
                        <Form.Item rules={[{ required: true }]} label="name" name="name"><Input /></Form.Item>
                        <Form.Item rules={[{ required: true }]} label="description" name="description"><Input /></Form.Item>
                        <Form.Item rules={[{ required: true }]} label="value" name="value"><Input.TextArea rows={6} /></Form.Item>
                    </Form>
                </Modal>

                <Drawer size='large' maskClosable={false} title="编辑" placement="right" onClose={() => { setDrawerVisible(false) }} visible={Drawervisible}>
                    <Form form={drawerForm} layout="vertical" onFinish={onEditFormFinish}>
                        <Form.Item hidden={true} label="key" name="key"><Input /></Form.Item>
                        <Form.Item hidden={true} label="namespace" name="namespace"><Input /></Form.Item>
                        <Form.Item rules={[{ required: true }]} label="name" name="name"><Input /></Form.Item>
                        <Form.Item rules={[{ required: true }]} label="description" name="description"><Input /></Form.Item>
                        <Form.Item rules={[{ required: true }]} label="value" name="value"><Input.TextArea rows={18} /></Form.Item>
                        <Space
                            key="action-buttons"
                            style={{ float: "right", marginTop: 24, marginRight: 24 }}
                        >
                            <Button onClick={() => setDrawerVisible(false)}>{translate("buttons.cancel", "Cancel")}</Button>
                            <Button type="primary" icon={<SaveOutlined spin={mutateIsLoading} />} htmlType="submit">
                                {translate("buttons.save", "Save")}
                            </Button>
                        </Space>
                    </Form>
                </Drawer>

            </LayoutWrapper>
        </Authenticated>
    );
};
