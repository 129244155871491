import React, { useState } from "react";

import { useTranslate, useLogout, useTitle, useNavigation } from "@pankod/refine-core";
import { AntdLayout, Menu, Grid, Icons } from "@pankod/refine-antd";
import { antLayoutSider, antLayoutSiderMobile } from "./styles";
import { useMenu } from "hooks/useMenu";
import { useConf } from "hooks/useConf";

const { SubMenu } = Menu;
const { RightOutlined, LogoutOutlined, DashboardOutlined,FolderOpenOutlined } = Icons;
const { SettingFilled, UserOutlined, FireFilled, CodeSandboxOutlined, GiftFilled } = Icons;

export const Sider: React.FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const { mutate: logout } = useLogout();
  const Title = useTitle();
  const translate = useTranslate();
  const { nsItems } = useConf();
  const { menuItems, selectedKey, initRootSubmenu, ns } = useMenu();
  const { push } = useNavigation();
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.lg;

  const subMenuKeys = initRootSubmenu(['config', 'user']);
  const [openKeys, setOpenKeys] = React.useState(subMenuKeys);

  return (
    <AntdLayout.Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
      collapsedWidth={isMobile ? 0 : 80}
      breakpoint="lg"
      style={isMobile ? antLayoutSiderMobile : antLayoutSider}
    >
      {Title && <Title collapsed={collapsed} />}
      <Menu
        selectedKeys={[selectedKey]}
        openKeys={openKeys} onOpenChange={setOpenKeys}
        mode="inline"
        onClick={({ key }) => {
          if (key === "logout") {
            logout();
            return;
          }

          if (!breakpoint.lg) {
            setCollapsed(true);
          }

          push(key as string);
        }}
      >
        <Menu.Item key="/" icon={<DashboardOutlined />}
          style={{ fontWeight: "/" === selectedKey ? "bold" : "normal" }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            {translate("dashboard.title", "Dashboard")}
            {!collapsed && ("/" === selectedKey) && <RightOutlined />}
          </div>
        </Menu.Item>
        <Menu.Item key="/resource" icon={<FolderOpenOutlined />}
          style={{ fontWeight: "/resource" === selectedKey ? "bold" : "normal" }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            资源管理
            {!collapsed && ("/resource" === selectedKey) && <RightOutlined />}
          </div>
        </Menu.Item>

        <SubMenu key="config" icon={<SettingFilled />} title="通用配置">
          {nsItems.map((item) => {
            const isSelected = item.route === selectedKey && item.name === ns;
            return (
              <Menu.Item key={item.key} icon={item.icon} style={{ fontWeight: isSelected ? "bold" : "normal" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} >
                  {item.label} {!collapsed && isSelected && <RightOutlined />}
                </div>
              </Menu.Item>
            );
          })}
          {/* <Menu.Item key="/config/level">等级/经验/体力/材料</Menu.Item>
          <Menu.Item key="/config/health_points">消耗体力</Menu.Item>
          <Menu.Item key="/config/checkin_loop">7天循环签到</Menu.Item>
          <Menu.Item key="/config/mkv_hold">持有mkv</Menu.Item>
          <Menu.Item key="/config/lp_mining">LP挖矿</Menu.Item>
          <Menu.Item key="/config/nft_level_cost">NFT等级消耗材料</Menu.Item>
          <Menu.Item key="/config/nft_level_power">NFT等级算力</Menu.Item>
          <Menu.Item key="/config/week_shop">每周商店</Menu.Item>
          <Menu.Item key="/config/daily_lottery">每日抽奖设置</Menu.Item>
          <Menu.Item key="/config/random_material">随机材料概率</Menu.Item> */}
        </SubMenu>
        <SubMenu key="user" icon={<UserOutlined />} title="用户管理">
          <Menu.Item key="/user/list">用户列表</Menu.Item>
          <Menu.Item key="/user/white_list">白名单列表</Menu.Item>
        </SubMenu>
        <Menu.Item key="/issued_list" icon={<FireFilled />}>NFT发行列表</Menu.Item>
        <Menu.Item key="/mysterybox" icon={<GiftFilled />}>盲盒销售</Menu.Item>
        <Menu.Item key="/game_list" icon={<CodeSandboxOutlined />}>游戏列表</Menu.Item>


        <SubMenu key="resource" icon={<SettingFilled />} title="Demo">
          {menuItems.map(({ icon, label, route }) => {
            const isSelected = route === selectedKey;
            return (
              <Menu.Item
                style={{
                  fontWeight: isSelected ? "bold" : "normal",
                }}
                key={route}
                icon={icon}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {label}
                  {!collapsed && isSelected && <RightOutlined />}
                </div>
              </Menu.Item>
            );
          })}
        </SubMenu>

        <Menu.Item key="logout" icon={<LogoutOutlined />}>
          {translate("buttons.logout", "Logout")}
        </Menu.Item>
      </Menu>
    </AntdLayout.Sider>
  );
};
