import {
    useTranslate,
    Authenticated,
    LayoutWrapper,
    useOne,
    useDelete,
    BaseRecord,
    useApiUrl,
} from "@pankod/refine-core";

import {
    Table,
    TextField,
    DateField,
    Card,
    Row,
    Col,
    PageHeader,
    Space,
    Icons,
    Drawer,
    Input,
} from "@pankod/refine-antd";
import { Button, Form, List, message, Popconfirm, Select, Typography, Upload } from "antd";
import { useState } from "react";
import { axiosInstance } from "authProvider";
import axios from "axios";
import JSON5 from "json5";

const resource = "resource";
const STATIC_PATH = process.env.REACT_APP_STATIC_PATH ?? "";
export const Resource: React.FC = () => {

    const t = useTranslate();
    const { data: resp, isLoading, refetch, isFetching } = useOne({ resource, id: "list" });
    const record = resp?.data.data;


    const { data: nsResp } = useOne({ resource: "config", id: "base/resource" });
    const nsRecord = JSON5.parse(nsResp?.data.data ?? "{}");
    const recordArr = Object.keys(nsRecord).map((x) => nsRecord[x]);
    const NameSpace: React.FC<{}> = () => {
        return (
            <Card bordered={false} title={"NameSpace"}>
                <List dataSource={[{ label: "全部" }, ...recordArr]}
                    renderItem={item => {
                        return <List.Item  >
                            <Typography.Link >{item.name ? `[${item.name}]` : ''} {item.label}</Typography.Link>
                        </List.Item>
                    }}
                />
            </Card>
        );
    };

    const { mutate: mutateDel, isLoading: isLoadingDel } = useDelete<BaseRecord>();
    const onButtonDelete = (id: any) => {
        mutateDel(
            { resource, id: `del/${id}` },
            { onSuccess: () => refetch() }
        );
    }
    const open = (url: string | URL) => {
        window.open(
            `${STATIC_PATH}/${url}`, "_blank",
            "toolbar=no, location=yes, directories=no, status=no, menubar=no, scrollbars=yes, resizable=no"
        );
    }

    // Drawer
    const apiUrl = useApiUrl();
    const [uploading, setUploading] = useState(false);
    const [Drawervisible, setDrawerVisible] = useState(false);
    const onFormFinish = async (values: any) => {
        const formData = new FormData();
        formData.append("namespace", values["namespace"]);
        formData.append("description", values["description"]);
        formData.append("file", values["files"][0].originFileObj);
        // console.log({values},Object.fromEntries(formData))
        setUploading(true);
        try {
            const url = `${apiUrl}/resource/upload`;
            const { data: response, status } = await axiosInstance.post(
                url, formData, { headers: { 'content-type': 'multipart/form-data' } }
            );
            if (status === 200) {
                const { code, message: msg } = response;
                if (code === 0) {
                    refetch(); setDrawerVisible(false);
                    drawerForm.resetFields();
                } else {
                    if (msg) message.error(msg);
                }
            }
            setUploading(false);
        } catch (error: any) {
            setUploading(false);
            if (axios.isAxiosError(error) && error.response) {
                if (error.response.data.message) {
                    return message.error(error.response.data.message);
                }
            }
            return message.error(error);
        }
    };
    const normFile = (e: any) => {
        if (Array.isArray(e)) return e;
        return e.file && e.fileList;
    };
    const [drawerForm] = Form.useForm();
    const UploadDrawer: React.FC<{}> = (props) =>
        <Drawer title={t("buttons.upload", "Upload")} placement="right" visible={Drawervisible} onClose={() => { setDrawerVisible(false) }} >
            <Form form={drawerForm} layout="vertical" onFinish={onFormFinish}>
                <Form.Item rules={[{ required: true }]} label="namespace" name="namespace">
                    <Select>
                        {recordArr.map((item) => (
                            <Select.Option key={item.name}>{item.label}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item rules={[{ required: true }]} label="description" name="description"><Input /></Form.Item>
                <Form.Item rules={[{ required: true }]} label="file" name="files" valuePropName="fileList" getValueFromEvent={normFile}>
                    <Upload.Dragger listType="picture" maxCount={1} beforeUpload={() => { return false }}>
                        <p className="ant-upload-text"> Drag & drop a file in this area </p>
                    </Upload.Dragger>
                </Form.Item>
            </Form>
            <Space key="action-buttons" style={{ float: "right", marginTop: 24, marginRight: 24 }} >
                <Button type="primary" icon={<Icons.SaveOutlined spin={uploading} />} htmlType="submit" onClick={() => drawerForm.submit()}>
                    {t("buttons.save", "Save")}
                </Button>
            </Space>
        </Drawer>;

    return (
        <Authenticated>
            <LayoutWrapper>
                <Row gutter={[16, 16]}>
                    <Col xl={6} lg={24} xs={24}>
                        <NameSpace />
                    </Col>
                    <Col xl={18} xs={24}>
                        <PageHeader title="资源管理" ghost={false} extra={
                            <Space key="extra-buttons" wrap>
                                <Button icon={<Icons.FileAddOutlined spin={isFetching} />} onClick={() => setDrawerVisible(true)} >
                                    {t("buttons.upload", "Upload")}
                                </Button>
                                {/* <Button icon={<Icons.RedoOutlined spin={isFetching} />} onClick={() => refetch()} > 
                                    {t("buttons.refresh", "Refresh")} 
                                </Button> */}
                            </Space>
                        }>
                            <Table rowKey="id" dataSource={record} >
                                <Table.Column title="namespace" dataIndex="namespace" />
                                <Table.Column title="id" dataIndex="id" render={(value, row: any) =>
                                    <Typography.Paragraph copyable={{ text: `${STATIC_PATH}/${row.saved_path}` }}>
                                        <TextField value={value} />
                                    </Typography.Paragraph>
                                } />
                                <Table.Column title="mimetype" dataIndex="mimetype" />
                                <Table.Column title="description" dataIndex="description" />
                                {/* <Table.Column title="saved_path" dataIndex="saved_path"/> */}
                                <Table.Column key="createdAt" dataIndex="createdAt" title="createdAt"
                                    render={(value) => (<DateField value={value} format="LLL" />)}
                                />
                                <Table.Column title={t("table.actions")} render={(_: any, row: any) => {
                                    return <Space>
                                        <Button icon={<Icons.EyeOutlined />} onClick={() => { open(row.saved_path) }}></Button>
                                        <Popconfirm
                                            key="delete" okType="danger" okText={t("buttons.delete", "Delete")}
                                            cancelText={t("buttons.cancel", "Cancel")} title={t("buttons.confirm", "Are you sure?")}
                                            okButtonProps={{ disabled: isLoading }} onConfirm={(): void => onButtonDelete(row.id)}
                                        >
                                            <Button danger loading={isLoadingDel} icon={<Icons.DeleteOutlined />}></Button>
                                        </Popconfirm>
                                    </Space>
                                }} />
                            </Table>
                        </PageHeader>
                    </Col>
                </Row>
                <UploadDrawer />
            </LayoutWrapper>
        </Authenticated>
    );
};


