import { Row, Col, Card, Typography } from "@pankod/refine-antd";
import { useTranslation } from "react-i18next";

import {
    RecentOrders,
    TrendingMenu,
} from "components/dashboard";

const { Text } = Typography;

export const DashboardPage: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Row gutter={[16, 16]}>
            <Col xl={17} lg={16} md={24} sm={24} xs={24}>
                <Card
                    title={
                        <Text strong>{t("dashboard.recentOrders.title")}</Text>
                    }
                >
                    <RecentOrders />
                </Card>
            </Col>
            <Col xl={7} lg={8} md={24} sm={24} xs={24}>
                <Card
                    title={
                        <Text strong>{t("dashboard.trendingMenus.title")}</Text>
                    }
                >
                    <TrendingMenu />
                </Card>
            </Col>
        </Row>
    );
};
