import React from "react";
import JSON5 from "json5";
import { ITreeMenu, useOne } from "@pankod/refine-core";
// import { IMenuItem } from "@pankod/refine-core";

type useMenuReturnType = {
    nsItems: ITreeMenu[];
    nsMaps: { [x: string]: ITreeMenu & { desc: string } };
};

export const useConf: () => useMenuReturnType = () => {

    const { data: resp } = useOne({ resource: "config", id: "base/namespace" });
    const record = JSON5.parse(resp?.data.data ?? "{}");

    // 强制转换为 Array
    const recordArr = Object.keys(record).map((x) => record[x]);
    const nsItems: ITreeMenu[] = React.useMemo(
        () => [
            ...recordArr.map((namespace: any) => {
                const route = `/config/common`;
                const key = `/config/common?ns=${namespace.name}`;
                return {
                    ...namespace, key, route,
                    label: namespace.label ?? namespace.name
                };
            }),
            {
                label: "NS管理",
                key: "/config/namespace",
                route: "/config/namespace",
            }
        ],
        [recordArr]
    );

    // 强制转换为 key-map
    const key = "name"
    const nsMaps = Object.keys(record)
        .map((x) => record[x])
        .filter((o) => o[key])
        .reduce((m, o) => { return (m[o[key]] = o, m) }, {})

    return {
        nsMaps,
        nsItems
    };
};
