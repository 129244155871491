import { BaseRecord, useOne, useTranslate, useUpdate } from "@pankod/refine-core";
import { Authenticated, LayoutWrapper } from "@pankod/refine-core";

import {
    Typography,
    Button,
    Space,
    PageHeader,
    Icons,
    Spin,
    Card,
    Table,
    Drawer,
    Form,
    Input,
    Modal,
    InputNumber,
    message,
    FormInstance,
    Popconfirm,
} from "@pankod/refine-antd";
import { useEffect, useRef, useState } from "react";

const { Title, Text } = Typography;

const { EditOutlined, RedoOutlined, SaveOutlined, DeleteOutlined } = Icons;

// reset form fields when modal is form, closed
const useResetFormOnCloseModal = ({ form, visible }: { form: FormInstance; visible: any }) => {
    const prevVisibleRef = useRef<boolean>();
    useEffect(() => {
        prevVisibleRef.current = visible;
    }, [visible]);
    const prevVisible = prevVisibleRef.current;

    useEffect(() => {
        if (!visible && prevVisible) {
            form.resetFields();
        }
    });
};

const rowKey = "day"
const resource = "config"
const conf_key = "user/checkin_loop"
export const Checkin_loop: React.FC = () => {

    const translate = useTranslate();
    const [editTable] = Form.useForm();
    const [editingKey, setEditingKey] = useState();
    const editableAction = (_: any, row: any, index: any) => {
        return row[rowKey] === editingKey ? (<Space>
            <Typography.Link onClick={() => { editTable.submit() }} style={{ marginRight: 8 }}>Save</Typography.Link>
            <Button type="text" onClick={() => { setEditingKey(undefined); }}>Cancel</Button>
        </Space>) : (
            <Space>
                <Button icon={<EditOutlined />} onClick={() => {
                    setEditingKey(row[rowKey]);
                    editTable.setFieldsValue({ ...row });
                }}></Button>
                <Popconfirm
                    key="delete" okType="danger"
                    okText={translate("buttons.delete", "Delete")}
                    cancelText={translate("buttons.cancel", "Cancel")}
                    title={translate("buttons.confirm", "Are you sure?")}
                    okButtonProps={{ disabled: isLoading }}
                    onConfirm={(): void => onEditFormDelete(row[rowKey])}
                >
                    <Button danger loading={isLoading} icon={<DeleteOutlined />}></Button>
                </Popconfirm>
            </Space>)
    }
    const renderEditableColumn = (row: any, dataIndex: string) => {
        if (row[rowKey] === editingKey) {
            return (
                <Form.Item name={dataIndex} style={{ margin: 0 }} >
                    <Input type="number" />
                </Form.Item>
            );
        }
        return <Text>{row[dataIndex]}</Text>;
    };

    const { data: resp, isLoading, refetch, isFetching } = useOne({
        id: conf_key, resource,
    });
    const buttonDisabled = isLoading || isFetching;
    const resetRecord = (recMap: { [x: string]: any; }) => {
        const recArray = Object.keys(recMap).map(
            (rowKeyIndex) => recMap[rowKeyIndex]
        );
        setRecord([...recArray]);
    };
    const [record, setRecord] = useState<object[]>([]);
    useEffect(() => {
        if (resp?.data?.data && !isFetching) {
            const recMap = JSON.parse(resp.data.data);
            resetRecord(recMap);
            console.log(`[${conf_key}]`, recMap, { isFetching });
        }
    }, [resp, isFetching]);
    const onEditFormDelete = (index: any) => {
        if (index !== undefined) {
            const recMap = JSON.parse(resp?.data.data);
            delete recMap[index];
            mutate({ resource, id: conf_key, values: recMap });
        }
    }
    const onEditFormFinish = (values: any) => {
        if (editingKey !== undefined) {
            const recMap = JSON.parse(resp?.data.data);
            recMap[editingKey] = { ...recMap[editingKey], ...values };
            resetRecord(recMap);
            mutate({ resource, id: conf_key, values: recMap }, {
                onSuccess: () => {
                    setEditingKey(undefined);
                }
            });
        }
    };

    // Drawer
    const [drawerValue, setDrawerValue] = useState("");
    const [Drawervisible, setDrawerVisible] = useState(false);
    const showDrawer = () => {
        setDrawerValue(resp?.data.data ?? "{}");
        setDrawerVisible(true);
    };
    const mutationResult = useUpdate<BaseRecord>();
    const { mutate, isLoading: mutateIsLoading } = mutationResult;
    const handleSaveJson = () => {
        // valid
        try {
            JSON.parse(drawerValue);
        } catch (error) {
            return message.error('不是有效的json');
        }

        // submit
        mutate({ resource, id: conf_key, values: JSON.parse(drawerValue) }, {
            onSuccess: (_) => {
                setDrawerVisible(false);
            },
        });
    };

    // modal
    const [form] = Form.useForm();
    const [modalVisible, setModalVisible] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    useResetFormOnCloseModal({ form, visible: modalVisible });
    const handleOk = (_: any) => {
        form.submit();
    };
    const onModalFormFinish = (values: any) => {
        const recMap = JSON.parse(resp?.data.data);
        recMap[values[rowKey]] = values;
        // resetRecord(recMap);

        // submit
        mutate({ resource, id: conf_key, values: recMap }, {
            onSuccess: () => {
                setModalLoading(false);
                setModalVisible(false);
            },
            onError: () => {
                setModalLoading(false);
            }
        });
    };

    return (
        <Authenticated>
            <LayoutWrapper>
                <PageHeader title="相关配置 > 7天循环签到" ghost={false} extra={
                    <Space key="extra-buttons" wrap>
                        <Button icon={<RedoOutlined spin={isFetching} />}
                            onClick={() => refetch()}
                        > {translate("buttons.refresh", "Refresh")} </Button>
                        <Button disabled={buttonDisabled} icon={<EditOutlined />}
                            onClick={() => showDrawer()}
                        > JSON </Button>
                    </Space>
                }>
                    <Spin spinning={buttonDisabled}>
                        <Card bordered={false} >
                            <Title level={5}>7天进行循环签到，如果用户断签，那就从第一天开始。签到按照如下固定参数设置，经验奖励和随机材料。</Title>
                            <Form form={editTable} onFinish={onEditFormFinish}>
                                <Table rowKey={rowKey} dataSource={record} >
                                    <Table.Column width='15%' title="天数" dataIndex="day" render={day => (<Text>第{day}天</Text>)} />
                                    <Table.Column title="奖励经验" dataIndex="exp" render={(_, row) => renderEditableColumn(row, "exp")} />
                                    <Table.Column title="随机材料" dataIndex="mat" render={(_, row) => renderEditableColumn(row, "mat")} />
                                    <Table.Column width='25%' title={translate("table.actions")} render={editableAction}/>
                                </Table>
                            </Form>
                            <div style={{ textAlign: "center", marginTop: 24 }} >
                                <Button type="primary" disabled={buttonDisabled} onClick={() => { setModalVisible(true) }}>
                                    添加配置
                                </Button>
                            </div>
                        </Card>
                    </Spin>
                </PageHeader>

                <Modal
                    title="添加配置" visible={modalVisible}
                    onOk={handleOk} onCancel={() => setModalVisible(false)}
                    footer={[
                        <Button key="back" onClick={() => setModalVisible(false)}>取消</Button>,
                        <Button key="submit" type="primary" loading={modalLoading} onClick={handleOk}>保存</Button>
                    ]}
                >
                    <Form form={form} labelCol={{ span: 6 }} onFinish={onModalFormFinish}>
                        <Form.Item rules={[{ required: true }]} label="天数" name="day">
                            <InputNumber style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item rules={[{ required: true }]} label="奖励经验" name="exp"><InputNumber style={{ width: '100%' }} /></Form.Item>
                        <Form.Item rules={[{ required: true }]} label="随机材料" name="mat"><InputNumber style={{ width: '100%' }} /></Form.Item>
                    </Form>
                </Modal>

                <Drawer title="编辑JSON" placement="right" onClose={() => { setDrawerVisible(false) }} visible={Drawervisible}>
                    <Input.TextArea rows={10} value={drawerValue} onChange={e => setDrawerValue(e.target.value)} />
                    <Space
                        key="action-buttons"
                        style={{ float: "right", marginTop: 24, marginRight: 24 }}
                    >
                        <Button type="primary" icon={<SaveOutlined spin={mutateIsLoading} />} htmlType="submit" onClick={handleSaveJson}>
                            {translate("buttons.save", "Save")}
                        </Button>
                    </Space>
                </Drawer>

            </LayoutWrapper>
        </Authenticated>
    );
};
