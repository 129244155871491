import { BaseRecord, useOne, useTranslate, useUpdate } from "@pankod/refine-core";
import { Authenticated, LayoutWrapper } from "@pankod/refine-core";

import {
    Typography,
    Button,
    Space,
    PageHeader,
    Icons,
    Spin,
    Card,
    Table,
    Drawer,
    Form,
    Input,
    Modal,
    InputNumber,
    message,
    FormInstance,
    Popconfirm,
} from "@pankod/refine-antd";
import { useEffect, useRef, useState } from "react";

const { EditOutlined, RedoOutlined, SaveOutlined, DeleteOutlined } = Icons;

// reset form fields when modal is form, closed
const useResetFormOnCloseModal = ({ form, visible }: { form: FormInstance; visible: any }) => {
    const prevVisibleRef = useRef<boolean>();
    useEffect(() => {
        prevVisibleRef.current = visible;
    }, [visible]);
    const prevVisible = prevVisibleRef.current;

    useEffect(() => {
        if (!visible && prevVisible) {
            form.resetFields();
        }
    });
};

const rowKey = "mat"
const resource = "config"
const conf_key = "base/random_material"
export const Random_material: React.FC = () => {

    const translate = useTranslate();
    const { data: resp, isLoading, refetch, isFetching } = useOne({ id: conf_key, resource });
    const resetRecord = (recMap: { [x: string]: any; }) => {
        const recArray = Object.keys(recMap).map(
            (rowKeyIndex) => recMap[rowKeyIndex]
        );
        setRecord([...recArray]);
        setDrawerValue(JSON.stringify(recMap));
    };
    const buttonDisabled = isLoading || isFetching;
    const [record, setRecord] = useState<object[]>([]);
    useEffect(() => {
        if (resp?.data?.data && !isFetching) {
            const recMap = JSON.parse(resp.data.data);
            resetRecord(recMap);
            console.log(`[${conf_key}]`, recMap, { isFetching });
        }
    }, [resp, isFetching]);
    const editableAction = (_: any, row: any, index: any) => {
        return <Space>
                <Popconfirm
                    key="delete" okType="danger"
                    okText={translate("buttons.delete", "Delete")}
                    cancelText={translate("buttons.cancel", "Cancel")}
                    title={translate("buttons.confirm", "Are you sure?")}
                    okButtonProps={{ disabled: isLoading }}
                    onConfirm={(): void => onEditFormDelete(row[rowKey])}
                >
                    <Button danger loading={isLoading} icon={<DeleteOutlined />}></Button>
                </Popconfirm>
        </Space>
    }
    const onEditFormDelete = (index: any) => {
        if (index !== undefined) {
            const recMap = JSON.parse(resp?.data.data);
            delete recMap[index];
            mutate({ resource, id: conf_key, values: recMap });
        }
    }

    // Drawer
    const [drawerValue, setDrawerValue] = useState("");
    const [Drawervisible, setDrawerVisible] = useState(false);
    const mutationResult = useUpdate<BaseRecord>();
    const { mutate, isLoading: mutateIsLoading } = mutationResult;
    const handleSaveJson = () => {
        // valid
        try {
            JSON.parse(drawerValue);
        } catch (error) {
            return message.error('不是有效的json');
        }

        // submit
        mutate({ resource, id: conf_key, values: JSON.parse(drawerValue) }, {
            onSuccess: () => {
                setDrawerVisible(false);
            },
        });
    };

    // modal
    const [form] = Form.useForm();
    const [modalVisible, setModalVisible] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    useResetFormOnCloseModal({ form, visible: modalVisible });
    const handleOk = (value: any) => {
        form.submit();
    };
    const onModalFormFinish = (values: any) => {
        const recMap = JSON.parse(resp?.data.data);
        recMap[values[rowKey]] = values;
        mutate({ resource, id: conf_key, values: recMap }, {
            onSuccess: () => {
                setModalLoading(false);
                setModalVisible(false);
            },
            onError: () => {
                setModalLoading(false);
            }
        });
    };
    const handleCancel = () => {
        setModalVisible(false);
    };

    return (
        <Authenticated>
            <LayoutWrapper>
                <PageHeader title="相关配置 > NFT等级算力" ghost={false} extra={
                    <Space key="extra-buttons" wrap>
                        <Button icon={<RedoOutlined spin={isFetching} />}
                            onClick={() => refetch()}
                        > {translate("buttons.refresh", "Refresh")} </Button>
                        <Button disabled={buttonDisabled} icon={<EditOutlined />}
                            onClick={() => setDrawerVisible(true)}
                        > JSON </Button>
                    </Space>
                }>
                    <Spin spinning={buttonDisabled}>
                        <Card bordered={false} >
                            <Typography.Title level={5}>系统设置赠送，并不是每种材料的概率都一样，有些材料好获得，有些难获得，所以增加材料获得概率。</Typography.Title>
                            <Table rowKey={rowKey} dataSource={record} >
                                <Table.Column title="材料" dataIndex="mat" />
                                <Table.Column title="概率(%)" dataIndex="factor" />
                                <Table.Column width='20%' title={translate("table.actions")} render={editableAction} />
                            </Table>
                            <div style={{ textAlign: "center", marginTop: 24 }} >
                                <Button type="primary" disabled={buttonDisabled} onClick={() => { setModalVisible(true) }}>
                                    添加配置
                                </Button>
                            </div>
                        </Card>
                    </Spin>
                </PageHeader>

                <Modal
                    title="添加配置" visible={modalVisible}
                    onOk={handleOk} onCancel={handleCancel}
                    footer={[
                        <Button key="back" onClick={handleCancel}>取消</Button>,
                        <Button key="submit" type="primary" loading={modalLoading} onClick={handleOk}>保存</Button>
                    ]}
                >
                <Form form={form} labelCol={{ span: 6 }} onFinish={onModalFormFinish}>
                    {/* <Form.Item rules={[{ required: true }]} label="材料" name="mat">
                        <Select>
                            <Select.Option value="hpe">Health Elixir</Select.Option>
                            <Select.Option value="pwe">Power Elixir</Select.Option>
                            <Select.Option value="sp">Spiritual Solution</Select.Option>
                            <Select.Option value="gav">Galaxy Vial</Select.Option>
                        </Select>
                    </Form.Item> */}
                    <Form.Item rules={[{ required: true }]} label="材料" name="mat"><Input /></Form.Item>
                    <Form.Item rules={[{ required: true }]} label="概率(%)" name="factor"><InputNumber style={{ width: '100%' }} /></Form.Item>
                </Form>
                </Modal>

                <Drawer title="编辑JSON" placement="right" onClose={() => { setDrawerVisible(false) }} visible={Drawervisible}>
                    <Input.TextArea rows={10} value={drawerValue} onChange={e => setDrawerValue(e.target.value)} />
                    <Space
                        key="action-buttons"
                        style={{ float: "right", marginTop: 24, marginRight: 24 }}
                    >
                        <Button type="primary" icon={<SaveOutlined spin={mutateIsLoading} />} htmlType="submit" onClick={handleSaveJson}>
                            {translate("buttons.save", "Save")}
                        </Button>
                    </Space>
                </Drawer>

            </LayoutWrapper>
        </Authenticated>
    );
};
